<template>
  <div class="mt--8 pb-5 container users-edit" v-if="user != null">
    <div class="card bg-secondary border-0">
      <b-row class="rule-edit">
        <b-col lg="10" class="mx-auto">
          <b-row class="border-bottom sticky-top bg-white page-header">
            <div class="d-flex align-items-center">
              <b-link @click="$router.go(-1)">
                <font-awesome-icon icon="arrow-left-long" />
              </b-link>
              <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">Back</h5>
              <b-button @click.prevent="saveSettings">Save</b-button>
            </div>
          </b-row>
          <b-form>
            <b-row>
              <b-col lg="12">
                <b-form-group
                  id="input-group-1"
                  label="Username"
                  label-for="username"
                  description="We'll never share your username with anyone else."
                >
                  <b-form-input
                    id="username"
                    name="username"
                    v-model="user.username"
                    type="text"
                    placeholder="Enter username"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group
                  id="input-group-2"
                  label="Email"
                  label-for="email"
                  description="We'll never share your email with anyone else."
                >
                  <b-form-input
                    id="email"
                    name="email"
                    v-model="user.email"
                    type="email"
                    placeholder="Enter email"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Role" label-for="roleId" class="mb-3">
                  <b-form-select
                    id="roleId"
                    v-model="user.roleId"
                    required
                    :options="options"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group
                  id="input-group-4"
                  label="New password"
                  label-for="password"
                >
                  <b-form-input
                    id="password"
                    name="password"
                    v-model="password"
                    type="password"
                    placeholder="Enter password"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import UserService from "../../services/user.service";
export default {
  name: "PageUserEdit",
  data: function () {
    return {
      user: null,
      roleId: 1,
      options: [
        {
          text: "User",
          value: 1,
        },
        {
          text: "Moderator",
          value: 2,
        },
        {
          text: "Admin",
          value: 3,
        },
      ],
      username: "",
      email: "",
      password: null,
      errors: {},
    };
  },
  computed: {
    userId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.loadUser();
  },
  methods: {
     logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    async loadUser() {
      const response = await UserService.getUserById("/users/" + this.userId);
      this.user = response.data.user;
      this.roleId = response.data.user.roleId;
      if (response.data == '') {
        this.logOut()
      }
    },
    async saveSettings() {
      this.errors = {};
      const response = await UserService.updateUser(
        "/users/edit/" + this.userId,
        {
          username: this.user.username,
          email: this.user.email,
          roleId: this.user.roleId,
          password: this.password,
        }
      );
      if (response) {
        this.user = response.data.user;
      }
      if (response.data == '') {
        this.logOut()
      }
    },
  },
};
</script>
<style scoped>
.bg-secondary {
  background-color: #f7fafc !important;
}
</style>
